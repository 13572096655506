import { useRouter } from 'next/router'
import { useContext } from 'react'
import { TConfigErrorStrings } from '../../services/config/Config'
import { UserServiceContext } from '../../services/user/UserService'
import { ErrorButton } from '../../type/Error'
import { isConnectedWeb } from '../../utils/appUtils'
import { Logger } from '../../utils/logger'
import { VideoErrorButton } from '../useVideoService'
import strings from './strings.json'

type TRawError = {
  status: number
  message: string
  code?: string
}

export type TUserErrorUiElements = {
  buttons: VideoErrorButton[]
  tvUrls: string[]
}

export type TUserError = TRawError & TConfigErrorStrings & TUserErrorUiElements

export type TUserErrorScope = 'page' | 'video'

type TUseUserErrorProps = {
  scope: TUserErrorScope
  rawError: TRawError
}

export default function useUserError({ scope, rawError }: TUseUserErrorProps): TUserError | undefined {
  const userContext = useContext(UserServiceContext)
  const router = useRouter()

  const errorStrings = strings[scope]
  const buttonLabels = strings.buttonLabels

  function getUiElements(code?: string): TUserErrorUiElements {
    let buttons: VideoErrorButton[] = []
    let tvUrls: string[] = []

    const helpUrl = strings['url.help']

    switch (scope) {
      case 'page':
        switch (code) {
          case 'not_found':
            buttons = [
              new ErrorButton({
                title: buttonLabels.backToHome,
                action: () => {
                  router.push('/')
                },
              }),
            ]
            break
          case 'out_of_market':
            if (userContext.isLoggedIn) {
              // If user unlocked they can change zip code in settings
              let actionUrl = '/settings'
              // If user is_locked they can only verify location
              if (userContext.currentUser?.profile.region.is_locked) {
                actionUrl = isConnectedWeb()
                  ? `/verify-location?next=${encodeURIComponent(router.asPath)}`
                  : '/location'
              }

              buttons = [
                new ErrorButton({
                  title: buttonLabels.updateHomeZip,
                  action: () => {
                    router.push(actionUrl)
                  },
                }),
              ]
            }
            break
          case 'network_error':
            buttons = [
              new ErrorButton({
                title: buttonLabels.retry,
                action: () => {
                  router.reload()
                },
              }),
            ]
            break
          default:
            buttons = [
              new ErrorButton({
                title: buttonLabels.retry,
                action: () => {
                  router.reload()
                },
              }),
            ]
            if (!isConnectedWeb()) {
              buttons.push(
                new ErrorButton({
                  title: buttonLabels.contactSupport,
                  style: 'alt',
                  action: () => {
                    router.push(strings['url.help'])
                  },
                })
              )
            }
            tvUrls = [helpUrl]
            break
        }
        break
      case 'video':
        switch (code) {
          case 'mvpd_required':
            buttons = isConnectedWeb()
              ? []
              : [
                  new VideoErrorButton({
                    title: buttonLabels.findTvProvider,
                    style: 'primary',
                    action: () => router.push('/mvpd/signin'),
                  }),
                ]
            break
          case 'unentitled_dtc_profile':
            buttons = isConnectedWeb()
              ? []
              : [
                  new VideoErrorButton({
                    title: buttonLabels.contactSupport,
                    style: 'primary',
                    action: () => router.push(helpUrl),
                  }),
                ]
            tvUrls = [helpUrl]
            break
          case 'unentitled_mvpd_profile':
            buttons = []
            break
          case 'couchrights_first_playback_failure':
            buttons = [
              new VideoErrorButton({
                title: buttonLabels.verifyLocation,
                style: 'primary',
                action: () => {
                  isConnectedWeb()
                    ? router.push(`/verify-location?next=${encodeURIComponent(router.asPath)}`)
                    : router.push('/location')
                },
              }),
              new VideoErrorButton(
                isConnectedWeb()
                  ? {
                      title: buttonLabels.cancel,
                      action: () => router.replace('/home'),
                    }
                  : {
                      title: buttonLabels.learnMore,
                      action: () => router.push(helpUrl),
                    }
              ),
            ]
            break
          case 'couchrights_grace_period_expired':
            buttons = [
              new VideoErrorButton({
                title: buttonLabels.verifyLocation,
                style: 'primary',
                action: () => {
                  isConnectedWeb()
                    ? router.push(`/verify-location?next=${encodeURIComponent(router.asPath)}`)
                    : router.push('/location')
                },
              }),
              new VideoErrorButton(
                isConnectedWeb()
                  ? {
                      title: buttonLabels.cancel,
                      action: () => router.replace('/home'),
                    }
                  : {
                      title: buttonLabels.learnMore,
                      action: () => router.push(strings['url.subscriberAgreement']),
                    }
              ),
            ]
            break
          case 'concurrency_limit_reached':
            buttons = [
              new VideoErrorButton({
                title: buttonLabels.manageDevices,
                style: 'primary',
                action: () => router.push('/settings/devices'),
              }),
            ]
            break
          case 'seasonal_entitlements_paused':
            buttons = isConnectedWeb()
              ? []
              : [
                  new VideoErrorButton({
                    title: buttonLabels.contactSupport,
                    style: 'primary',
                    action: () => router.push(helpUrl),
                  }),
                ]
            tvUrls = [helpUrl]
            break
          default:
            buttons = [
              new VideoErrorButton({
                title: buttonLabels.retry,
                style: 'primary',
                action: () => router.reload(),
              }),
            ]
            break
        }
        break
    }
    return { buttons, tvUrls }
  }

  function getUserError() {
    let fallbackUserError = Object.assign(
      {},
      errorStrings.find((e) => e.code === 'default_error') as TConfigErrorStrings
    )
    fallbackUserError.message = `${fallbackUserError.message}<br/>[${rawError.code}]`
    const userError = errorStrings.find((e) => e.code === rawError.code) || {}
    const finalError = { ...rawError, ...fallbackUserError, ...userError }
    Logger.of('useUserError').debug('data.userError produced from data.rawError', { rawError, finalError })
    return finalError
  }

  const { buttons, tvUrls } = getUiElements(rawError.code)
  const userError = getUserError()

  return { ...userError, buttons, tvUrls }
}
